<template>
  <div class="add-pro-wrapper">
    <!-- 标题与保存按钮 -->
    <el-row>
      <el-col :span="12"><Title title="添加"></Title></el-col>
      <el-col :span="12">
        <div style="width:100%;height:60px;padding-right:15px;box-sizing:border-box" class="flex_rec">
          <el-button type="primary" @click="save">保存</el-button>
        </div>
      </el-col>
    </el-row>

    <div class="add-pro-content">
      <el-row :gutter="15">
      <!-- 左模块 -->
        <el-col :span="18">
          <!-- 基本信息 -->
          <el-card class="box-card">
            <el-row :gutter="20">
              <!-- 左边 -->
              <el-col :span="4">
                <div class="part-info">
                  <div class="title">基本</div>
                  <div class="info">激活码基本信息</div>
                </div>
              </el-col>
              <!-- 右边 -->
              <el-col :span="20">
                <el-row :gutter="20">
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.name">
                        <template slot="prepend">类型名</template>
                      </el-input>
                  </el-col>
                </el-row>
                <el-input
                  type="textarea"
                  style="margin-top:10px"
                  :autosize="{ minRows: 4, maxRows: 6}"
                  placeholder="请输入内容"
                  v-model="form.comment">
                </el-input>
              </el-col>
            </el-row>
          </el-card>
          <!-- 套餐 -->
          <el-card class="box-card">
              <el-row :gutter="10" type="flex" justify="start" align="middle">
                <el-col :span="2">
                  激活时长
                </el-col>
                <el-col :span="4">
                  <el-select v-model="form.time_limit" placeholder="激活时长" style="width:100%" class="mb10">
                      <el-option
                        :key="op.value"
                        v-for="op in code_time_limit"
                        :label="op.name"
                        :value="op.value">
                      </el-option>
                  </el-select>
                </el-col>
                <el-col :span="2" style="text-align:center">
                  平台
                </el-col>
                <el-col :span="4">
                  <el-select v-model="form.platform" placeholder="平台" style="width:100%" class="mb10">
                      <el-option
                        :key="op.value"
                        v-for="op in code_platform"
                        :label="op.name"
                        :value="op.value">
                      </el-option>
                  </el-select>
                </el-col>
                <!-- 允许设备数 -->
                <el-col :span="3" style="text-align:center">
                  允许设备数
                </el-col>
                <el-col :span="4">
                    <el-input-number v-model="form.allow_device_num" :min="1" :max="999" label="允许设备数"></el-input-number>
                </el-col>
              </el-row>

              <!-- 价格 -->
              <el-row :gutter="10" type="flex" justify="start" align="middle">
                <el-col :span="2" style="text-align:center">
                  单设备价格
                </el-col>
                <el-col :span="4">
                    <el-input-number v-model="form.one_device_price" :min="0" :precision="2" :step="0.1" :max="9999"></el-input-number>
                </el-col>
                <el-col :span="3" style="text-align:center">
                  套餐价格
                </el-col>
                <el-col :span="4">
                    <el-input-number v-model="form.price" :min="0" :precision="2" :step="0.1" :max="9999"></el-input-number>
                </el-col>
              </el-row>
          </el-card>
        </el-col>

        <!-- 右模块 -->
        <el-col :span="6">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>状态</span>
            </div>
            <p class="p-extra mb5">启用状态</p>
            <el-select v-model="form.status" placeholder="请选择账号状态" style="width:100%" class="mb10">
              <el-option
                v-for="item in status_arr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- 添加商品表单结束 -->




  </div>
</template>

<script>
import Title from "./components/Title"
import apis from "@/api/index"
export default {
  name:'addProducts',
    components:{
      Title
    },
    data(){
      return{
        form:{
          name:'',
          comment:'',
          status:1,
          time_limit:365,
          platform:'win32',
          allow_device_num:1,
        },
        status_arr:[
          {name:'启用/激活',value:1},
          {name:'禁用/未激活',value:0},
        ],
        code_time_limit:[
          {name:"永久",value:0},
          {name:"年",value:365},
          {name:"季度",value:120},
          {name:"月",value:30},
          {name:"7天",value:7},
          {name:"1天",value:1},
          {name:"12H",value:0.5},
        ],
        code_platform:[
          {name:"苹果系统",value:'darwin'},
          {name:"windows",value:"win32"},
          {name:"win加mac",value:"all"},
          {name:"android",value:"android"},
          {name:"ios",value:"ios"},
        ],
      }
    },
    methods:{
      save(){
        console.log(this.form)
        apis.code_type.update(this.form).then(res=>{
          this.$message({message:res.data.msg,type:'success'})
          this.$router.push({name:'codetypeList'})
        }).catch(err=>{
          this.$message({message:err,type:'error'})
        })
      },
      readOne(){
        let p = this.$route.params.obj || {id:this.$route.query.id};
        console.log('p',p)
        if(!p.id) return;
        apis.code_type.readOne({id:p.id}).then(res=>{
          // this.form_loading = false;
          this.form = res.data.data
        }).catch(err=>{
          this.form_loading = false;
        })
      },
      imgUploadChange(){

      }
    },
    mounted(){
        if(!this.$route.params.obj){
          this.$router.go(-1)
          return;
        }
        this.readOne();
        console.log('mounted',this.$route)
    },
    created(){
      console.log('created',this.$route)
    },
    computed:{

    },
    watch:{
    }
}
</script>

<style lang="scss">
.add-pro-wrapper{
  .add-pro-content{
    box-sizing: border-box;
    width: 100%;
    padding:15px;
  }
  .box-card{
    margin-bottom:5px;
  }
  .part-info{
    // @include flex_ccc();
    // background: brown;
    .title{
      font-size: 18px;
      color:#333;
    }
    .info{
      font-size: 12px;
      color:#999;
    }
  }
}
</style>